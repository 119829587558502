<template>
  <div class="date-list-wrapper">
    <div class="date-list">
      <div
        class="date-grid months"
        :style="cssVar"
      >
        <div class="left-arrow-container" />
        <div
          v-for="(month, m) in months"
          :key="m"
          :style="[ m === 0 ? {'grid-column' : `${2} / ${getMonthWidth(months[m+1]) + 2}`}
            : {'grid-column' : `${getMonthWidth(months[m]) + 2} / ${getMonthWidth(months[m + 1]) + 2}`} ]"
          class="date-cell month"
        >
          <span class="stickyCol">{{ month }}</span>
        </div>
      </div>
      <div
        class="date-grid dates"
        :style="cssVar"
      >
        <div class="left-arrow-container border-top-0" />
        <div
          v-for="(date, d) in dateRange"
          :key="d"
          class="date-cell"
        >
          <span class="d-block day-text">{{ getDay(date) }}</span>
          <span>{{ getDateNumber(date) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CalendarDates',
  props: {
    dateRange: {
      type: Array,
    },
  },
  computed: {
    months() {
      return [...new Set(this.dateRange.map(item => moment(item).format('MMM, YYYY')).flat())]
    },
    cssVar() {
      return {
        '--cols': this.dateRange.length,
        '--minWidth': `${(this.dateRange.length * 60) + 200}px`,
      }
    },
  },
  methods: {
    getDay(date) {
      return moment(date).format('ddd')
    },
    getDateNumber(date) {
      return moment(date).format('DD')
    },
    getMonthWidth(month) {
      if (month) {
        const date = month.split(' ')
        const monthNumber = moment().month(date[0]).format('M')
        return moment(new Date(date[1], monthNumber - 1, 1)).diff(moment(this.dateRange[0]), 'days')
      }
      return this.dateRange.length
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/base/colors.scss';
@import '~@core/scss/base/themes/dark-layout.scss';

.date-list-wrapper {
  position: relative;
  .date-list {
    position: relative;
    min-width: min-content;
    background-color: $card-bg;
    .date-grid {
      display: grid;
      grid-template-columns: 200px repeat(var(--cols), 60px);
      pointer-events: none;
      text-align: right;
      min-width: var(--minWidth);
      height: 100%;
      .left-arrow-container {
        position: sticky;
        left: 0;
        z-index: 5;
        background: $card-bg;
        border: 1px solid $border-color;
        border-bottom-width: 0;
        padding: 4px 8px;
      }
      .date-cell {
        padding: 10px 10px;
        border-left: 1px solid $border-color;
        span {
          font-size: 12px;
        }
        .day-text {
          color: $body-color;
        }
      }
    }
    .months {
      text-align: left;
      .stickyCol {
        position: sticky;
        left: 220px;
      }
      .month {
        span {
          font-size: 12px;
        }
      }
      .date-cell {
        padding: 5px 10px 0;
        color: $secondary;
        font-weight: 500;
      }
    }
  }
}
.dark-layout {
  .date-list-wrapper {
    .date-list {
      background-color: $theme-dark-card-bg;
      .date-grid {
        .left-arrow-container {
          background: $theme-dark-card-bg;
          border-color: $theme-dark-border-color;
        }
        .date-cell {
          border-left: 1px solid $theme-dark-border-color;
        }
      }
    }
  }
}
</style>
