<template>
  <div
    class="calendar-list-view"
  >
    <div class="date-wrapper">
      <b-row align-items-center>
        <b-col
          cols="12"
          md="9"
        >
          <h3>{{ tourData.name }}</h3>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="date-picker">
            <b-form-group>
              <flat-pickr
                v-model="copyDateRange"
                class="date-picker"
                :config="datePickerConfig"
                @on-change="datePickrSelect"
                @on-close="getDates"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <div
        class="calendar-dates horizontal-scroll"
        @scroll="scrollMovement(0)"
      >
        <CalendarDates :date-range="dateList" />
      </div>
    </div>
    <!-- option -->
    <div class="option-name">
      <b-row class="justify-content-between align-items-center option-wrapper">
        <b-col cols="6">
          <b-dropdown
            :text="selectedOption.name"
            variant="flat-primary"
          >
            <b-dropdown-item
              v-for="(option, i) in optionList"
              :key="i"
              @click="getDataForSelectedOption(option)"
            >
              {{ option.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="6">
          <div class="d-flex justify-content-end">
            <b-button
              variant="flat-primary"
              @click="openBulkEditSidebar(selectedOption)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span class="align-middle">Bulk Edit</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="calendar-data horizontal-scroll"
      :style="[!allowUserSelect ? {'pointer-events' : 'none'} : '']"
      @scroll="scrollMovement(1)"
    >
      <div
        class="row-data"
      >
        <template v-for="(obj, i) in keys">
          <CalendarRowData
            :key="i"
            :row-data="getSelectedOptions(selectedOption.id)"
            :option-id="selectedOption.id"
            :key-info="obj.key"
            :title="obj.title"
            :is-active="currentSelect === `option-${i}`"
            :dom-id="`option-${i}`"
            :dates="dateList"
            @selection="getCurrentActive"
            @showPopOver="getData"
            @hidePopover="hide"
          />
        </template>
      </div>
    </div>
    <!--  sub-option -->
    <div
      v-for="(suboption, j) in getSubOptions"
      :key="j"
    >
      <div
        class="option-name"
      >
        <b-row class="justify-content-between align-items-center option-wrapper">
          <b-col cols="6">
            <p class="text-primary font-weight-bold m-0 p-1">
              {{ suboption.name }}
            </p>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-end">
              <b-button
                variant="flat-primary"
                @click="openBulkEditSidebar(suboption)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span class="align-middle">Bulk Edit</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        class="calendar-data horizontal-scroll"
        :style="[!allowUserSelect ? {'pointer-events' : 'none'} : '']"
        @scroll="scrollMovement(j+2)"
      >
        <div
          class="row-data"
        >
          <template
            v-for="(obj, i) in keys"
          >
            <CalendarRowData
              :key="i"
              :row-data="getSelectedOptions(suboption.id)"
              :option-id="suboption.id"
              :key-info="obj.key"
              :title="obj.title"
              :is-active="currentSelect === `option-${suboption.id}-${i}`"
              :dom-id="`option-${suboption.id}-${i}`"
              :dates="dateList"
              @selection="getCurrentActive"
              @showPopOver="getData"
              @hidePopover="hide"
            />
          </template>
        </div>
      </div>
    </div>
    <CalendarPopoverForm
      v-if="showPopover"
      :option="selectedData"
      :show-popover="showPopover"
      @updateAvailabilityData="getAvailabilityData"
      @closePopover="closePopover"
    />
    <AvailabilityForm
      v-if="allowUserSelect"
      :prop-option="formOption"
      :option-id="parseInt(optionId)"
      @getAvailabilityData="getAvailabilityData"
    />
  </div>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import CalendarRowData from '@/views/availability/list-view/CalendarRowData.vue'
import CalendarDates from '@/views/availability/list-view/CalendarDates.vue'
import CalendarPopoverForm from '@/views/availability/list-view/CalendarPopoverForm.vue'
import AvailabilityForm from '@/views/availability/AvailabilityForm.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'

export default {
  name: 'AvailabilityList',
  components: {
    CalendarRowData,
    CalendarDates,
    CalendarPopoverForm,
    AvailabilityForm,
    flatPickr,
  },
  mixins: [helper, calendar],
  data() {
    return {
      formOption: {},
      optionId: '',
      selectedStartDate: '',
      selectedEndData: '',
      availabilityList: [],
      dateList: [],
      showPopover: false,
      dateRange: [
        moment(new Date()).format('YYYY-MM-DD'),
        moment(new Date()).add(14, 'days').format('YYYY-MM-DD'),
      ],
      copyDateRange: [
        moment(new Date()).format('YYYY-MM-DD'),
        moment(new Date()).add(14, 'days').format('YYYY-MM-DD'),
      ],
      selectedData: {},
      currentSelect: '',
      tourData: {},
      optionAvailabilityList: [],
      optionList: [],
      selectedOption: {},
      optionSelectCount: 2,
      allowUserSelect: true,
      keys: [{ key: 'unit', title: 'Availability' },
        { key: 'sold', title: 'Sold' },
        { key: 'bar_price', title: 'Bar Price' },
        { key: 'bar_price_child', title: 'Bar Price Child' },
      ],
      datePickerConfig: {
        mode: 'range',
        wrap: true,
        altFormat: 'j M, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        conjunction: '-',
        minDate: null,
        maxDate: null,
      },
    }
  },
  computed: {
    getSubOptions() {
      if (this.selectedOption.child_options) {
        return this.selectedOption.child_options
      }
      return []
    },
  },
  mounted() {
    this.getTourData()
  },
  methods: {
    getSelectedOptions(id) {
      const ele = this.availabilityList.filter(option => option.option === id)
      const populateOptionsById = []
      this.dateList.forEach((date, i) => {
        let found
        if (ele.length) {
          found = ele.find(option => option.date === date)
        }
        if (found) {
          populateOptionsById[i] = found
        } else {
          populateOptionsById[i] = {}
        }
      })
      return populateOptionsById
    },
    getTourData() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.tourData = response.data.result
            this.optionList = response.data.result.all_options
            const firstItem = this.optionList[0]
            this.selectedOption = firstItem
            this.generateDates()
          }
        }).catch().finally(() => {
        })
    },
    getAvailabilityData() {
      const start = this.dateList[0]
      const end = this.dateList[this.dateList.length - 1]
      api.getData(`tour/admin_services/availability_list/?tour_id=${this.$route.params.id}&date_from=${start}&date_to=${end}`, true)
        .then(response => {
          if (response.data.status) {
            this.allowUserSelect = true
            const availabilities = response.data.result.sort((a, b) => moment(a.date)
              .format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'))
            this.availabilityList = availabilities
          } else {
            this.allowUserSelect = false
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.allowUserSelect = false
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        })
    },
    hide() {
      this.showPopover = false
    },
    // eslint-disable-next-line no-unused-vars
    getDates(date) {
      this.dateRange = [date[0], date[1]]
      this.datePickerConfig.minDate = null
      this.datePickerConfig.maxDate = null
      this.generateDates()
    },
    datePickrSelect(e) {
      this.optionSelectCount += 1
      if (this.optionSelectCount % 2 !== 0) {
        this.datePickerConfig.minDate = new Date(e)
        this.datePickerConfig.maxDate = new Date(e).fp_incr(14)
      }
    },
    generateDates() {
      let currentDate = moment(this.dateRange[0])
      const endDate = moment(this.dateRange[1])
      this.dateList = []
      while (currentDate <= endDate) {
        this.dateList.push(moment(currentDate).format('YYYY-MM-DD'))
        currentDate = moment((currentDate).add(1, 'days'))
      }
      this.getAvailabilityData()
    },
    getDataForSelectedOption(option) {
      this.selectedOption = option
    },
    getData(event, data) {
      this.selectedStartDate = this.dateList[data.start - 1]
      this.selectedEndData = this.dateList[data.end - 1]
      this.currentSelect = data.id
      this.preparePopoverData(data)
    },
    preparePopoverData(data) {
      const averageDom = Math.round(((data.start - 1) + (data.end - 1)) / 2)
      const dom = document.querySelector(`#col-${averageDom}-${data.id}`).getBoundingClientRect()
      this.selectedData = JSON.parse(JSON.stringify(data.chosenData))
      this.selectedData.startIndex = data.start - 1
      this.selectedData.endIndex = data.end - 1
      this.selectedData.title = data.title
      this.selectedData.optionId = data.optionId
      this.selectedData.start_date = this.selectedStartDate
      this.selectedData.end_date = this.selectedEndData
      const sideBarDom = document.querySelector('.main-menu').getBoundingClientRect().width
      this.selectedData.popoverXPosition = dom.left - dom.width - sideBarDom - 100
      this.selectedData.popoverYPosition = dom.bottom - dom.height
      this.showPopover = true
    },
    getCurrentActive(title) {
      this.currentSelect = title
      this.showPopover = false
    },
    closePopover() {
      this.showPopover = false
      this.currentSelect = ''
      this.selectedData = {}
    },
    openBulkEditSidebar(option) {
      this.formOption = option
      this.$root.$emit('bv::toggle::collapse', 'bulk-edit')
    },
    scrollMovement(index) {
      const toBeScroll = document.querySelectorAll('.horizontal-scroll')
      const leftPosition = toBeScroll[index].scrollLeft
      for (let i = 0; i < toBeScroll.length; i += 1) {
        if (i === index) {
          // eslint-disable-next-line no-continue
          continue
        }
        toBeScroll[i].scrollLeft = leftPosition
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.date-picker {
  .date-picker.form-control {
    background-color: $body-bg;
  }
}
.date-wrapper {
  .calendar-dates {
    overflow-x: auto;
  }
}
.calendar-list-view {
  .calendar-data {
    position: relative;
    overflow-x: auto;
    background-color: $card-bg;
  }
  .option-name {
    background-color: $card-bg;
    border: 1px solid $border-color;
    border-right: 0;
    .dropdown.b-dropdown {
      .dropdown-menu.show {
        max-height: 250px;
        overflow-y: auto;
      }
      button.btn {
        padding: 15px 10px;
        border-radius: 0;
      }
    }
  }
}
.dark-layout {
  .date-picker {
    .date-picker.form-control {
      background-color: $theme-dark-body-bg;
    }
  }
  .calendar-list-view {
    .calendar-data {
      background-color: $theme-dark-card-bg;
    }
    .option-name {
      background-color: $theme-dark-card-bg;
      border: 1px solid $theme-dark-border-color;
      border-right: 0;
    }
  }
}
</style>
